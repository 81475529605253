import { ScaleSVG } from '@vx/responsive';
import { Pie } from '@vx/shape';
import { Group } from '@vx/group';

type Props = {
  data: Array<{
    label?: string;
    value: number;
    color: string;
  }>;
  bg: string;
  width: number;
  height: number;
};

function Circle({ data, width, height, bg }: Props) {
  const radius = Math.min(width, height) / 2;
  const centerY = height / 2;
  const centerX = width / 2;
  const labelr = radius - 45;
  const cornerRadius = 30;

  return (
    <ScaleSVG width={width} height={height}>
      <Group top={centerY} left={centerX}>
        <Pie
          data={data}
          pieValue={d => d.value}
          outerRadius={radius - 80}
          innerRadius={radius - 110}
          cornerRadius={cornerRadius}
          padRadius={0}
          padAngle={0}
          pieSort={null}
          sortValues={null}
        >
          {pie => {
            return (
              <>
                <path
                  fill={bg}
                  d={pie.path({ startAngle: 0, endAngle: 360 })}
                />
                {[...pie.arcs].reverse().map((arc, i) => {
                  const [centroidX, centroidY] = pie.path.centroid(arc);
                  // https://stackoverflow.com/questions/8053424/label-outside-arc-pie-chart-d3-js
                  const h = Math.sqrt(
                    centroidX * centroidX + centroidY * centroidY
                  );
                  const x = (centroidX / h) * labelr;
                  const y = (centroidY / h) * labelr;
                  return (
                    <g key={`circle-path-${arc.data.label}-${i}`}>
                      <path
                        d={pie.path({
                          ...arc,
                          startAngle: Math.max(0, arc.startAngle - cornerRadius)
                          // endAngle
                        })}
                        fill={arc.data.color}
                      />
                      {arc.data.label && (
                        <text
                          fill="#333333"
                          x={x}
                          y={y}
                          dy=".33em"
                          fontSize={14}
                          textAnchor="middle"
                        >
                          {arc.data.label}
                        </text>
                      )}
                    </g>
                  );
                })}
              </>
            );
          }}
        </Pie>
      </Group>
    </ScaleSVG>
  );
}

export default Circle;
