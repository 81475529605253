import React from 'react';

import { useSettingsQuery } from '../../lib/core/SettingsQuery';
import { videoUrls } from '../../lib/constants';
import styled, { css } from 'styled-components';
import { Video, Box } from '../../components';

const DemonstrationWrapper = styled(Box)<{ bottomBorder: boolean }>`
  ${({ theme, bottomBorder }) =>
    bottomBorder &&
    css`
      position: relative;
      &:after {
        content: '';
        display: block;
        bottom: 0;
        margin: 0 auto;
        border-bottom: 2px solid ${theme.colors.neutral[1]};
        margin-bottom: -2px;
        width: 100%;
        ${theme.mediaQueries[2]} {
          width: 70%;
        }
      }
    `}
`;

export default function DemonstrationVideo({
  video,
  ...props
}: {
  video: string;
  showPlayButton?: boolean;
}) {
  const settingsQueryProps = useSettingsQuery();
  const system = settingsQueryProps?.data?.system;

  if (!system) {
    return null;
  }

  const videoObj = videoUrls[video];

  if (!videoObj) {
    return null;
  }

  const videoSrcObj = videoObj.sources;
  const videoUrlData =
    system.id && videoSrcObj[system.id]
      ? videoSrcObj[system.id]
      : videoSrcObj['default'];

  if (!videoUrlData) {
    return null;
  }
  return (
    <DemonstrationWrapper bottomBorder={videoObj.bottomBorder}>
      <Video {...videoUrlData} {...props} />
    </DemonstrationWrapper>
  );
}
