import Head from 'next/head';

export default function MapboxCss() {
  return (
    <Head>
      <link
        key="mapbox-css"
        href="https://api.tiles.mapbox.com/mapbox-gl-js/v2.10.0/mapbox-gl.css"
        rel="stylesheet"
      />
    </Head>
  );
}
