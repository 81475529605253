import { useRef, useState, FC } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Box,
  Text,
  H2,
  Button,
  Flex,
  ButtonGroup,
  I18NPluralValueMinutes
} from '../../components';
import Container from '../HowItWorks/Container';
import TripDurationFigure from './TripDurationFigure';
import FeatureVideo from './FeatureVideo';
import HowItWorksFeature from './HowItWorksFeature';
import DemonstrationVideo from './DemonstrationVideo';
import TripStatsFeature from './TripStatsFeature';
import FeatureImage from './FeatureImage';
import PageFeature from './PageFeature';
import StationMapFeature from './StationMapFeature';
import InfoFeature from './InfoFeature';
import {
  FeatureBlocks as FeatureBlocksFragment,
  FeatureModule_figureModule,
  MultiFeatureModule_featureModules,
  featureModuleTripDuration
} from '../../core-types';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { BoxProps } from '@urbaninfrastructure/react-ui-kit';
import { BlockContent } from '../../components/BlockContent';
import { useStandardRentalMins } from '../../lib/core/useStandardRentalMins';

export function FeatureHeading(props: any) {
  return (
    <Box mb="sm">
      <H2 typoStyle="lg" {...props} />
    </Box>
  );
}

export function FeatureSection(props: any) {
  return <Box textAlign="center" {...props} />;
}

export function FeatureDescription(props: any) {
  return (
    <Box mx="auto" maxWidth="xs">
      <Text typoStyle="sm" {...props} />
    </Box>
  );
}

function FigureModule({ figureModule }) {
  if (!figureModule) {
    return null;
  }
  return figureModule.map(m => {
    if (m.video) {
      return <DemonstrationVideo key={m._key} video={m.video} />;
    }
    return null;
  });
}

const FeatureModule: FC<{
  title: string | null;
  description: any | null;
  figureModule?: FeatureModule_figureModule[] | null;
}> = ({ children, title, description, figureModule, ...props }) => {
  return (
    <FeatureSection {...props}>
      <Container maxWidth="none">
        {title && <FeatureHeading>{title}</FeatureHeading>}
        {description && (
          <FeatureDescription>
            <BlockContent blocks={description} />
          </FeatureDescription>
        )}
        {children}
      </Container>
      {figureModule && <FigureModule figureModule={figureModule} />}
    </FeatureSection>
  );
};

const FEATURE_MODULE_TRIP_DURATION_QUERY = gql`
  query featureModuleTripDuration {
    systemStats {
      medianDurationThisYear
    }
  }
`;

function FeatureModuleTripDuration({
  title,
  description,
  ...props
}: {
  title: string | null;
  description: any | null;
}) {
  const { data, loading } = useQuery<featureModuleTripDuration>(
    FEATURE_MODULE_TRIP_DURATION_QUERY,
    { ssr: false }
  );
  const standardRentalMinsResult = useStandardRentalMins();
  let seconds = 0;
  if (data && data.systemStats) {
    seconds = data.systemStats.medianDurationThisYear;
  }
  const minutes = Math.floor(seconds / 60);
  const pluralMinutes = loading ? (
    '...'
  ) : (
    <I18NPluralValueMinutes values={{ minutes }} />
  );

  return (
    <FeatureModule title={title} description={description} {...props}>
      <>
        {!!minutes && (
          <Text as="p" typoStyle="xs" mb={0}>
            <FormattedMessage
              id="modules.FeatureBlocks.FeatureModuleTripDuration.descriptionInSeason"
              defaultMessage="Did you know: Average trip length this year is {pluralMinutes}"
              values={{
                pluralMinutes
              }}
            />
          </Text>
        )}
        <Box maxWidth="xs" mx="auto">
          <TripDurationFigure
            key={seconds}
            durationSeconds={
              (standardRentalMinsResult.standardRentalMins || 0) * 60
            }
            medianSeconds={seconds}
          />
        </Box>
      </>
    </FeatureModule>
  );
}

function MultiFeatureModule({
  title,
  featureModules,
  ...props
}: {
  title: string | null;
  featureModules: MultiFeatureModule_featureModules[] | null;
}) {
  const elRef = useRef<HTMLDivElement | null>(null);
  const [view, setView] = useState(
    featureModules && featureModules[0] && featureModules[0]._key
  );

  function onButtonClick(event, view) {
    setView(view);
    if (elRef.current) {
      elRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
      event.preventDefault();
    }
  }

  return (
    <Box ref={elRef} {...props}>
      <FeatureSection>
        <Container>
          <FeatureHeading>{title}</FeatureHeading>
        </Container>
        {featureModules &&
          featureModules.map(featureModule => {
            if (view !== featureModule._key) {
              return null;
            }
            return (
              <FeatureModule
                key={featureModule._key}
                title={null}
                description={featureModule.description}
                figureModule={featureModule.figureModule}
              />
            );
          })}

        <Flex my="sm" justifyContent="center">
          <ButtonGroup segment>
            {featureModules &&
              featureModules.map(featureModule => {
                if (!featureModule) {
                  return null;
                }
                const key = featureModule._key;
                return (
                  <Button
                    key={key}
                    shape="cool"
                    ml="auto"
                    variant={view === key ? 'primary' : undefined}
                    onClick={event => {
                      onButtonClick(event, key);
                    }}
                  >
                    {featureModule.title}
                  </Button>
                );
              })}
          </ButtonGroup>
        </Flex>
      </FeatureSection>
    </Box>
  );
}

function FeatureBlock({
  featureBlock,
  prevFeatureBlock
}: {
  featureBlock: FeatureBlocksFragment;
  prevFeatureBlock: FeatureBlocksFragment | undefined;
}) {
  // variant is only on some union types
  const variant = 'variant' in featureBlock && featureBlock.variant;
  const prevVariant =
    prevFeatureBlock &&
    'variant' in prevFeatureBlock &&
    prevFeatureBlock.variant;

  const blockProps: BoxProps = {
    mb: 'lg',
    pt: variant === 'primary' ? 'lg' : undefined,
    bg: 'white',
    color: 'text'
  };

  if (variant) {
    switch (variant) {
      case 'primary': {
        blockProps.bg = 'primary';
        blockProps.color = 'primaryContrast';
        blockProps.pb = blockProps.mb;
        blockProps.mb = undefined;
        break;
      }
      case 'subtleWhite': {
        blockProps.bg = 'neutral.1';
        blockProps.pb = blockProps.mb;
        blockProps.mb = undefined;
        break;
      }
      default:
        return null;
    }
  }

  // reset padding top if previous block was of type primary
  if (
    variant &&
    prevVariant &&
    ['subtleWhite', 'primary'].includes(prevVariant) &&
    prevVariant === variant
  ) {
    blockProps.pb = 'lg';
    blockProps.pt = undefined;
    blockProps.mb = undefined;
  }

  // set padding top if previous block was of type primary, and current block is subtleWhite (or not defined, meaning subtleWhite)
  if (prevVariant === 'primary' && (!variant || variant === 'subtleWhite')) {
    blockProps.pt = 'lg';
  }

  switch (featureBlock.__typename) {
    case 'SanityFeatureModule': {
      return (
        <FeatureModule
          title={featureBlock.title}
          description={featureBlock.description}
          figureModule={featureBlock.figureModule}
          {...blockProps}
        />
      );
    }
    case 'SanityFeatureModuleTripDuration': {
      return (
        <FeatureModuleTripDuration
          title={featureBlock.title}
          description={featureBlock.description}
          {...blockProps}
        />
      );
    }
    case 'SanityMultiFeatureModule': {
      return (
        <MultiFeatureModule
          title={featureBlock.title}
          featureModules={featureBlock.featureModules}
          {...blockProps}
        />
      );
    }
    case 'SanityFeatureVideo': {
      return (
        <FeatureVideo
          title={featureBlock.title}
          videoUrl={featureBlock.videoUrl}
          autoplay={featureBlock.autoplay}
          poster1x1={featureBlock.poster1x1}
          poster16x9={featureBlock.poster16x9}
          {...blockProps}
        />
      );
    }
    case 'SanityFeatureImage': {
      return <FeatureImage featureBlock={featureBlock} {...blockProps} />;
    }
    case 'SanityHowItWorksFeatureBlock': {
      return (
        <HowItWorksFeature
          steps={featureBlock.steps}
          demonstrationVideo={featureBlock.demonstrationVideo}
          {...blockProps}
        />
      );
    }
    case 'SanityStationMapFeatureBlock': {
      return (
        <StationMapFeature
          description={featureBlock.description}
          {...blockProps}
        />
      );
    }
    case 'SanityTripStatsFeatureBlock': {
      return (
        <TripStatsFeature
          phraseGroups={featureBlock.phraseGroups}
          {...blockProps}
        />
      );
    }
    case 'SanityPageFeatureBlock': {
      return (
        <PageFeature
          title={featureBlock.title}
          pageRefType={featureBlock.pageRefType}
          {...blockProps}
        />
      );
    }
    case 'SanityInfoBlock': {
      return (
        <InfoFeature
          title={featureBlock.title}
          description={featureBlock.description}
          link={featureBlock.link}
          emotion={featureBlock.emotion}
          {...blockProps}
        />
      );
    }
    default: {
      // eslint-disable-next-line no-console
      console.warn(`Feature block not found.`);
      return null;
    }
  }
}

const FeatureBlocks = ({
  featureBlocks,
  appContent
}: {
  featureBlocks: FeatureBlocksFragment[] | null;
  appContent: boolean;
}) => {
  if (!featureBlocks) {
    return null;
  }
  return (
    <>
      {featureBlocks.map((featureBlock, i) => {
        if (!featureBlock) {
          return null;
        }

        if (
          featureBlock.__typename === 'SanityInfoBlock' ||
          featureBlock.__typename === 'SanityFeatureImage' ||
          featureBlock.__typename === 'SanityFeatureModule' ||
          featureBlock.__typename === 'SanityTripStatsFeatureBlock' ||
          featureBlock.__typename === 'SanityStationMapFeatureBlock' ||
          featureBlock.__typename === 'SanityPageFeatureBlock'
        ) {
          if (featureBlock.hideFromApps && appContent) {
            return null;
          }
        }

        const prevFeatureBlock = featureBlocks[i - 1];

        return (
          <FeatureBlock
            key={featureBlock._key}
            featureBlock={featureBlock}
            prevFeatureBlock={prevFeatureBlock}
          />
        );
      })}
    </>
  );
};

export default FeatureBlocks;
