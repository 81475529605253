import { useRef } from 'react';
import Image from 'next/image';
import { Box, Anchor } from '../../components';
import Container from '../HowItWorks/Container';
import { FeatureBlocks_SanityFeatureImage } from '../../core-types';
import SanityLink, { isValidSanityLink } from '../../components/SanityLink';

export default function FeatureImage({
  featureBlock,
  ...props
}: {
  featureBlock: FeatureBlocks_SanityFeatureImage;
}) {
  const target = useRef<HTMLDivElement>(null);

  if (!featureBlock.image || !featureBlock.image.url) {
    return null;
  }

  let originalImageHeight = 16;
  let originalImageWidth = 9;
  if (featureBlock.image.metadata && featureBlock.image.metadata.dimensions) {
    if (featureBlock.image.metadata.dimensions.height !== null) {
      originalImageHeight = featureBlock.image.metadata.dimensions.height;
    }
    if (featureBlock.image.metadata.dimensions.width !== null) {
      originalImageWidth = featureBlock.image.metadata.dimensions.width;
    }
  }

  const { alt } = featureBlock;

  const image = (
    <Image
      src={featureBlock.image.url}
      width={originalImageWidth}
      height={originalImageHeight}
      alt={alt || ''}
    />
  );

  const containerProps = { display: 'flex', justifyContent: 'center' };

  if (featureBlock.link && isValidSanityLink(featureBlock.link)) {
    return (
      <Box ref={target} {...props}>
        <Container {...containerProps}>
          <SanityLink link={featureBlock.link}>
            {anchorProps => {
              return (
                <Anchor {...anchorProps} style={{ maxWidth: '100%' }}>
                  {image}
                </Anchor>
              );
            }}
          </SanityLink>
        </Container>
      </Box>
    );
  }

  return (
    <Box ref={target} {...props}>
      <Container {...containerProps}>{image}</Container>
    </Box>
  );
}
