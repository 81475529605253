import { gql, useQuery } from '@apollo/client';
import {
  systemConnectionPriceMatrixesQuery,
  PriceMatrixItemType
} from '../../core-types';

const SYSTEM_CONNECTION_PRICE_MATRIXES_QUERY = gql`
  query systemConnectionPriceMatrixesQuery {
    system {
      id
      connectionPriceMatrixes {
        id
        priceMatrix {
          matrix {
            minutes
            price
            type
          }
        }
      }
    }
  }
`;

/**
 * Sum up number of free periods in the system to simulate deprecated system.standardRentalMins
 */
export function useStandardRentalMins(): {
  loading: boolean;
  standardRentalMins: number | null;
} {
  const { data, loading } = useQuery<systemConnectionPriceMatrixesQuery>(
    SYSTEM_CONNECTION_PRICE_MATRIXES_QUERY,
    { ssr: false }
  );
  let standardRentalMins: number | null = null;
  const connectionPriceMatrixes =
    data && data.system && data.system.connectionPriceMatrixes;
  if (connectionPriceMatrixes) {
    standardRentalMins = 0;
    for (const connectionPriceMatrix of connectionPriceMatrixes) {
      for (const priceMatrixItem of connectionPriceMatrix.priceMatrix.matrix) {
        if (
          priceMatrixItem.type === PriceMatrixItemType.period &&
          priceMatrixItem.price === 0
        ) {
          standardRentalMins += priceMatrixItem.minutes;
        }
      }
    }
  }
  return { loading, standardRentalMins };
}
