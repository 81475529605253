import { gql, useQuery } from '@apollo/client';
import dynamic from 'next/dynamic';
import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntersection } from 'use-intersection';
import {
  Box,
  ButtonAnchor,
  Flex,
  FlexEmbed,
  Link,
  Loading,
  Text
} from '../../components';
import { BlockContent } from '../../components/BlockContent';
import { dockGroupsQuery } from '../../core-types';
import Container from '../HowItWorks/Container';
import MapboxCss from '../StationMap/MapboxCss';

const StationMap = dynamic(import('../StationMap'), {
  ssr: false,
  // eslint-disable-next-line react/display-name
  loading: () => <Loading />
});

type Props = {
  description: string | null;
};

const getRandomItem = arr => arr[Math.floor(Math.random() * arr.length)];

const br = <br />;

const DOCK_GROUPS_QUERY = gql`
  query dockGroupsQuery {
    dockGroups {
      id
      name
      title
      subTitle
      state
      coord {
        lat
        lng
      }
      availabilityInfo {
        availableDocks
        availableVehicles
      }
    }
    system {
      id
      GBFS
      publicTripHistory
      coords {
        lat
        lng
      }
    }
  }
`;

export default function StationMapFeature({ description, ...props }: Props) {
  const elRef = useRef<HTMLDivElement>(null);

  const intersected = useIntersection(elRef, {
    threshold: 0.1,
    rootMargin: '200px',
    once: true
  });

  const { data } = useQuery<dockGroupsQuery>(DOCK_GROUPS_QUERY, {
    ssr: false,
    skip: !intersected
  });

  const loadingIndicator = '…';
  let fromDockGroup;
  let toDockGroup;
  let numberOfStations: string | number = loadingIndicator;
  let showHeadingFromTo;
  let showHeadingStations;

  // TODO: remove this when we have a better way to handle this
  //Remove the title for Rouen
  if (data?.system?.id === 'inurba-rouen') {
    showHeadingFromTo = false;
    showHeadingStations = false;
  } else {
    if (data && data.dockGroups) {
      const { dockGroups } = data;
      showHeadingFromTo = dockGroups.length > 1;
      showHeadingStations = dockGroups.length > 2;
      const activeDockGroups = dockGroups.filter(
        dg => dg && dg.state === 'active'
      );
      numberOfStations = activeDockGroups.length - 2;
      fromDockGroup = getRandomItem(dockGroups);
      toDockGroup = getRandomItem(
        dockGroups.filter(d => d && fromDockGroup && d.id !== fromDockGroup.id)
      );
    }
  }

  return (
    <Box bg="neutral.1" ref={elRef} {...props}>
      <Container pt="lg">
        <Container maxWidth="xs" px={0}>
          {(showHeadingFromTo || showHeadingStations) && (
            <Text typoStyle="lg" mb="xs">
              {showHeadingFromTo && (
                <FormattedMessage
                  id="modules.FeatureBlocks.StationMapFeature.headingFromTo"
                  defaultMessage="From {fromStationTitle}{br}to {toStationTitle}"
                  values={{
                    fromStationTitle: fromDockGroup
                      ? fromDockGroup.title
                      : loadingIndicator,
                    toStationTitle: toDockGroup
                      ? toDockGroup.title
                      : loadingIndicator,
                    br
                  }}
                />
              )}
              {showHeadingStations && (
                <Text display="block" as="span" color="primaryText">
                  <FormattedMessage
                    id="modules.FeatureBlocks.StationMapFeature.headingStations"
                    defaultMessage="{numberOfStations, plural, one {and {numberOfStations} other {br} station} other {and {numberOfStations} other {br} stations}}"
                    values={{
                      numberOfStations,
                      br
                    }}
                  />
                </Text>
              )}
            </Text>
          )}
          <Box mb="md">
            <BlockContent blocks={description} />
          </Box>
        </Container>

        <FlexEmbed
          ratio={{ xs: { width: 1, height: 1 }, lg: { width: 16, height: 9 } }}
          borderRadius="lg"
          boxShadow="heavy"
        >
          {intersected && (
            <>
              <MapboxCss />
              <Flex
                position="absolute"
                top={0}
                zIndex={1}
                width="100%"
                height="100%"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                color="white"
              >
                <Link route="stationMapPage" passHref>
                  <ButtonAnchor
                    variant="primary"
                    shape="cool"
                    boxShadow="light"
                  >
                    <FormattedMessage
                      id="modules.FeatureBlocks.StationMapFeature.openMap"
                      defaultMessage="Open map"
                    />
                  </ButtonAnchor>
                </Link>
              </Flex>
              <StationMap
                mapType="dynamic"
                view="bikes"
                enableAnimatedTrips={false}
                disableMapControls
                mapHeight="100%"
              />
            </>
          )}
        </FlexEmbed>
      </Container>
    </Box>
  );
}
