import { useRef, useReducer } from 'react';
import Image from 'next/image';
import { FormattedMessage } from 'react-intl';
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion';
import {
  Play as PlayIcon,
  Pause as PauseIcon
} from '@urbaninfrastructure/react-icons';
import {
  Flex,
  Box,
  Text,
  IconButton,
  FlexEmbed,
  I18NContinue,
  Hide
} from '../../components';
import { StyledVideo, flexEmbedRatio } from '../../components/Video';
import Container from '../HowItWorks/Container';
import {
  FeatureVideo_poster16x9,
  FeatureVideo_poster1x1
} from '../../core-types';

type State = {
  isPlayed: boolean;
  isPlaying: boolean;
  isPaused: boolean;
  isHovered: boolean;
};

type Action =
  | { type: 'PLAY' }
  | { type: 'PAUSE' }
  | { type: 'ENDED' }
  | { type: 'MOUSE_ENTER' }
  | { type: 'MOUSE_ENTER' }
  | { type: 'MOUSE_LEAVE' };

const initialState = {
  isPlayed: false,
  isPlaying: false,
  isPaused: false,
  isHovered: false
};

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'PLAY':
      return { ...state, isPlayed: true, isPlaying: true, isPaused: false };
    case 'PAUSE':
      return { ...state, isPlaying: false, isPaused: true };
    case 'ENDED':
      return { ...state, isPlaying: false, isPaused: false };
    case 'MOUSE_ENTER':
      return { ...state, isHovered: true };
    case 'MOUSE_LEAVE':
      return { ...state, isHovered: false };
    default:
      return state;
  }
}

export default function FeatureVideo({
  title,
  videoUrl,
  autoplay,
  poster16x9,
  poster1x1,
  ...props
}: {
  title: string | null;
  videoUrl: string | null;
  autoplay?: boolean | null;
  poster16x9: FeatureVideo_poster16x9 | null;
  poster1x1: FeatureVideo_poster1x1 | null;
}) {
  const videoEl = useRef<HTMLVideoElement>(null);
  const reducedMotion = useReducedMotion();

  if (autoplay) {
    initialState.isPlaying = true;
    initialState.isPlayed = true;
  }

  const [{ isPlaying, isPaused, isHovered, isPlayed }, dispatch] = useReducer(
    reducer,
    initialState
  );

  function handlePlay() {
    if (videoEl.current) {
      videoEl.current.play();
      dispatch({ type: 'PLAY' });
    }
  }
  function handlePause() {
    if (videoEl.current) {
      videoEl.current.pause();
      dispatch({ type: 'PAUSE' });
    }
  }

  const showPause = isHovered && isPlaying && !isPaused;

  return (
    <Box {...props}>
      <Container>
        <Box
          borderRadius={1}
          position="relative"
          onMouseEnter={() => {
            dispatch({ type: 'MOUSE_ENTER' });
          }}
          onMouseLeave={() => {
            dispatch({ type: 'MOUSE_LEAVE' });
          }}
          onClick={() => {
            if (isPlaying) {
              if (isPaused) {
                handlePlay();
              } else {
                handlePause();
              }
            }
          }}
          mb="lg"
        >
          <FlexEmbed ratio={flexEmbedRatio} borderRadius="lg" boxShadow="heavy">
            <Flex
              position="absolute"
              top={0}
              zIndex={1}
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
              color="white"
              style={{ textShadow: '0 0 8px rgba(0, 0, 0, 0.4)' }}
            >
              <AnimatePresence initial={false}>
                {!isPlaying && (
                  <Flex
                    as={motion.div}
                    key="playIntro"
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    width="100%"
                    height="100%"
                    bg={!isPlaying ? 'rgba(0,0,0,.4)' : undefined}
                  >
                    <Text typoStyle="lg" mb="xs" px="xs" textAlign="center">
                      {title}
                    </Text>
                    <IconButton
                      variant="reset"
                      bg="transparent"
                      color="white"
                      Icon={PlayIcon}
                      onClick={() => {
                        if (videoEl.current) {
                          videoEl.current.play();
                        }
                      }}
                    >
                      {!isPaused && (
                        <FormattedMessage
                          id="FeatureVideo.playVideo"
                          defaultMessage="Play movie"
                        />
                      )}
                      {isPaused && <I18NContinue />}
                    </IconButton>
                  </Flex>
                )}
                {showPause && (
                  <motion.div
                    key="pause"
                    initial={{
                      opacity: 0,
                      scale: reducedMotion ? 1 : 1.5
                    }}
                    animate={{
                      opacity: 1,
                      scale: 1
                    }}
                    exit={{
                      opacity: 0,
                      scale: reducedMotion ? 1 : 1.5
                    }}
                    style={{ position: 'absolute' }}
                  >
                    <PauseIcon size="48px" />
                  </motion.div>
                )}
              </AnimatePresence>
            </Flex>
            {!isPlayed && (
              <>
                {poster1x1 && poster1x1.url && (
                  <Hide lg xl>
                    <Image
                      src={poster1x1.url}
                      alt=""
                      width="1000"
                      height="1000"
                      priority
                    />
                  </Hide>
                )}
                {poster16x9 && poster16x9.url && (
                  <Hide xs sm md>
                    <Image
                      src={poster16x9.url}
                      alt=""
                      width="1600"
                      height="900"
                      priority
                    />
                  </Hide>
                )}
              </>
            )}
            <StyledVideo
              ref={videoEl}
              display={isPlayed ? 'block' : 'none'}
              poster={(poster16x9 && poster16x9.url) || undefined}
              width="100%"
              src={videoUrl || undefined}
              onPlay={() => {
                handlePlay();
              }}
              onEnded={() => {
                dispatch({ type: 'ENDED' });
              }}
              onPause={() => {
                handlePause();
              }}
              loop
              playsInline
              preload="none"
              autoPlay={autoplay || undefined}
              muted={autoplay || undefined}
            />
          </FlexEmbed>
        </Box>
      </Container>
    </Box>
  );
}
