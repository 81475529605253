import { FC } from 'react';
import { Text, Box, H2, Link, Button, ButtonAnchor } from '../../components';
import Container from '../HowItWorks/Container';
import { Info as InfoIcon } from '@urbaninfrastructure/react-icons';
import { ButtonVariant, BoxProps } from '@urbaninfrastructure/react-ui-kit';
import { settings_settings_navigation } from '../../core-types';
// @ts-ignore
import EmitionNegativeSceptical from '../../public/static/assets/emotions/negative_sceptical.svg';
// @ts-ignore
import EmotionPositiveFlirting from '../../public/static/assets/emotions/positive-flirting.svg';
// @ts-ignore
import EmotionPositivePleased from '../../public/static/assets/emotions/positive-pleased.svg';
import { BlockContent } from '../../components/BlockContent';

type Props = {
  title: string | null;
  description: any | null;
  link: settings_settings_navigation | null;
  emotion: string | null;
} & BoxProps;

const SanityLinkButton: FC<{
  shape: 'cool';
  variant: ButtonVariant;
  link: settings_settings_navigation;
}> = ({ link, ...props }) => {
  const { page, externalLink, showIntercom } = link;

  if (page) {
    const { slug } = page;
    const params = {
      slug: slug && slug.current ? slug.current : null
    };
    return (
      <Link route={page._type} params={params} passHref>
        <ButtonAnchor {...props} />
      </Link>
    );
  } else if (externalLink) {
    return <ButtonAnchor href={externalLink} {...props} />;
  } else if (showIntercom) {
    return (
      <Button
        {...props}
        onClick={() => {
          if (!window.Intercom) {
            return;
          }
          window.Intercom('show');
        }}
      />
    );
  }

  return null;
};

const emotionMap = {
  positiveFlirting: EmotionPositiveFlirting,
  positivePleased: EmotionPositivePleased,
  negativeSceptical: EmitionNegativeSceptical,
  iconInfo: InfoIcon
};

const defaultBg = 'primary';

export default function InfoFeature({
  title,
  description,
  link,
  emotion,
  color,
  ...props
}: Props) {
  const EmotionComponent = emotion && emotionMap[emotion];
  const isIcon = emotion && emotion.startsWith('icon');
  let iconProps: { size?: number; width?: number } = {};

  if (isIcon) {
    iconProps.size = 50;
  } else {
    iconProps.width = 200;
  }

  return (
    <Box color={color as any} {...props} textAlign="center">
      <Container maxWidth="md">
        {EmotionComponent && (
          <Text mb={isIcon ? 'xs' : undefined}>
            <EmotionComponent {...iconProps} />
          </Text>
        )}
        {title && (
          <H2 typoStyle="lg" mb="sm">
            {title}
          </H2>
        )}
        <Box mb="sm">
          <BlockContent blocks={description} />
        </Box>
        {link && link.title && (
          <SanityLinkButton
            shape="cool"
            variant={props.bg === defaultBg ? 'inverted' : 'primary'}
            link={link}
          >
            {link.title}
          </SanityLinkButton>
        )}
      </Container>
    </Box>
  );
}

InfoFeature.defaultProps = {
  bg: defaultBg,
  color: 'white'
};
