import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Text, I18NPluralTrips } from '../../components';
import Container from '../HowItWorks/Container';
import randomPhrase from './randomPhrase';
import {
  TripStatsFeatureBlock_phraseGroups,
  tripsTodayQuery
} from '../../core-types';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { useIntersection } from 'use-intersection';
import { BoxProps } from '@urbaninfrastructure/react-ui-kit';

type Props = {
  phraseGroups: (TripStatsFeatureBlock_phraseGroups | null)[] | null;
} & BoxProps;

const TRIPS_TODAY_QUERY = gql`
  query tripsTodayQuery {
    systemStats {
      tripsToday
    }
  }
`;

export default function TripStatsFeature({
  phraseGroups,
  color,
  ...props
}: Props) {
  const elRef = useRef<HTMLDivElement | null>(null);
  const isIntersected = useIntersection(elRef, {
    threshold: 0.1,
    rootMargin: '200px'
  });

  const { data, loading, error } = useQuery<tripsTodayQuery>(
    TRIPS_TODAY_QUERY,
    {
      ssr: false,
      skip: !isIntersected
    }
  );

  if (error) {
    return null;
  }

  let tripCount = !data || loading ? undefined : 0;
  let phrase = null;
  if (data && data.systemStats) {
    tripCount = data.systemStats.tripsToday;
    phrase = randomPhrase({
      tripCount: tripCount,
      phraseGroups
    });
  }

  return (
    <Box color={color as any} {...props} ref={elRef}>
      <Container data-testid="Contaienr" maxWidth="xs">
        <Text typoStyle="lg">
          <FormattedMessage
            id="modules.FeatureBlocks.TripStatsFeature"
            defaultMessage="{tripCount} {pluralTrips}{br}so far today.{br}{phrase}"
            values={{
              br: <br />,
              tripCount,
              pluralTrips: (
                <Text as="span" color="primaryText">
                  {tripCount === undefined ? (
                    '…'
                  ) : (
                    <I18NPluralTrips values={{ count: tripCount || 0 }} />
                  )}
                </Text>
              ),
              phrase
            }}
          />
        </Text>
      </Container>
    </Box>
  );
}
