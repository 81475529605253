import { isNil } from '../../lib/util';

import { TripStatsFeatureBlock_phraseGroups } from '../../core-types';

type Props = {
  tripCount: number;
  phraseGroups: (TripStatsFeatureBlock_phraseGroups | null)[] | null;
};

const getRandomItem = arr => arr[Math.floor(Math.random() * arr.length)];

// return a random string from a phraseGroup where threshold is larger or equal to tripCount
export default function randomPhrase({ tripCount, phraseGroups }: Props) {
  let defaultPhrase;
  if (!phraseGroups || isNil(tripCount)) {
    return defaultPhrase;
  }

  let group = phraseGroups.find(phraseGroup => {
    if (!phraseGroup) {
      return;
    }
    return tripCount <= phraseGroup.threshold;
  });

  if (!group) {
    const sorted = [...phraseGroups].sort((a, b) => {
      if (a && b) {
        return a.threshold < b.threshold ? 1 : -1;
      }
      return 0;
    });
    if (!sorted[0] || sorted[0].threshold > tripCount) {
      return defaultPhrase;
    } else {
      group = sorted[0];
    }
  }

  if (!group || !group.phrases || group.phrases.length === 0) {
    return defaultPhrase;
  }

  const phrase = getRandomItem(group.phrases);

  if (!phrase) {
    return defaultPhrase;
  }

  return phrase;
}
