import * as React from 'react';
import { Box, H2 } from '../../components';
import Container from '../HowItWorks/Container';
import CurrentUserSubscription from '../BuyProduct/CurrentUserSubscription';
import Products from '../BuyProduct/Products';

type Props = {
  title: string | null;
  pageRefType: string | null;
};

export default function PageFeature({ title, pageRefType, ...props }: Props) {
  let component: React.ReactNode;
  switch (pageRefType) {
    case 'buyProductPage': {
      component = (
        <>
          <H2 typoStyle="lg" textAlign="center" mb="md">
            {title}
          </H2>
          <Box color="text">
            <CurrentUserSubscription />
            <Products listName="Page feature block" />
          </Box>
        </>
      );
      break;
    }
    default: {
      return null;
    }
  }
  if (!component) {
    return null;
  }
  return (
    <Box {...props}>
      <Container maxWidth="md">{component}</Container>
    </Box>
  );
}
