import { useState, useEffect } from 'react';
import { ThemeConsumer } from 'styled-components';
import Circle from './Circle';
import { secondsToMMSS } from '../../lib/util';

const ONE_HOUR_IN_SECONDS = 3600;

type Data = Array<{
  label?: string;
  value: number;
  color: string;
}>;

const labelFontSize = 14;

function DurationLine({ data }: { data: Data }) {
  const width = 400;
  const height = 80;
  const barHeight = 35;
  const labelPadding = 30;
  return (
    <svg width={width + labelPadding * 2} height={height}>
      {data.map((d, i) => {
        return (
          <svg key={i} x={labelPadding}>
            <text
              fill="#333333"
              x={Math.max(0, d.value * 4 - 30)}
              y={barHeight}
              fontSize={labelFontSize}
              textAnchor="bottom"
            >
              {d.label}
            </text>
            <rect
              x={0}
              y={height - barHeight}
              rx={barHeight / 2}
              ry={barHeight / 2}
              width={d.value * 4}
              height={barHeight}
              fill={d.color}
            />
          </svg>
        );
      })}
    </svg>
  );
}

function DurationCircle({ data, bg }: { bg: string; data: Data }) {
  const dataWithBg = [
    ...data,
    {
      value: data.reduce((accum, d) => {
        return accum - d.value;
      }, 100),
      color: bg
    }
  ].filter(d => d.value);
  return <Circle data={dataWithBg} height={400} width={400} bg={bg} />;
}

const secondsToValue = s => (s / 60 / 60) * 100;

export default function TripDurationFigure(props: {
  durationSeconds: number;
  medianSeconds: number;
}) {
  const [values, setValues] = useState({
    durationSeconds: 0,
    medianSeconds: 0
  });
  useEffect(() => {
    setValues({
      durationSeconds: props.durationSeconds,
      medianSeconds: props.medianSeconds
    });
  }, [props.durationSeconds, props.medianSeconds]);
  const { medianSeconds, durationSeconds } = values;

  return (
    <ThemeConsumer>
      {theme => {
        const primaryColor = theme.colors.primary;
        const secondaryColor = theme.colors.neutral[4];

        const medianColor = medianSeconds ? primaryColor : secondaryColor;
        const durationColor =
          medianColor === primaryColor ? secondaryColor : primaryColor;

        if (durationSeconds > ONE_HOUR_IN_SECONDS) {
          const max = Math.max(medianSeconds, durationSeconds);
          const data = [
            {
              label: `${Math.floor(durationSeconds / 60 / 60)} h`,
              value: (durationSeconds / max) * 100,
              color: durationColor
            },
            {
              label: `${secondsToMMSS(medianSeconds, false, false)} min`,
              value: (medianSeconds / max) * 100,
              color: theme.colors.primary
            }
          ];
          return <DurationLine data={data} />;
        }
        const bg = theme.colors.neutral[2];
        const durationValue = secondsToValue(durationSeconds - medianSeconds);
        const medianValue = secondsToValue(medianSeconds);
        const data = [
          {
            label: `${secondsToMMSS(medianSeconds, false, false)} min`,
            value: medianValue,
            color: medianColor
          },
          {
            label: `${secondsToMMSS(durationSeconds, false, false)} min`,
            value: durationValue,
            color: durationColor
          }
        ];
        return <DurationCircle bg={bg} data={data} />;
      }}
    </ThemeConsumer>
  );
}
