import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { CssGrid, CssGridProps } from '@urbaninfrastructure/react-ui-kit';
import { Box, Text, H3, Anchor, Link } from '../../components';
import Container from '../HowItWorks/Container';
import DemonstrationVideo from './DemonstrationVideo';
import { HowItWorksFeatureBlock_steps } from '../../core-types';
import { BlockContent } from '../../components/BlockContent';
import { useSystem } from '../../lib/core/useSystem';

type Props = {
  steps: HowItWorksFeatureBlock_steps[] | null;
  demonstrationVideo: string | null;
};

const CustomCounterList = styled(CssGrid)<CssGridProps>`
  counter-reset: custom-counter;
  list-style: none;
`;

CustomCounterList.withComponent('ol');

const CustomCounterLi = styled.li`
  counter-increment: custom-counter;
  h3::before {
    margin-right: 0.5rem;
    content: counter(custom-counter);
    color: ${({ theme }) => theme.colors.primaryText};
  }
`;

export default function HowItWorksFeature({
  steps,
  demonstrationVideo,
  ...props
}: Props) {
  const system = useSystem();
  const systemId = system.id;

  if (!steps) {
    return null;
  }

  const boxProps = !demonstrationVideo ? props : {};

  return (
    <Container>
      {/*Set custom max width for Rouen system*/}
      <Box
        mx="auto"
        maxWidth={systemId === 'inurba-rouen' ? 1200 : 'sm'}
        textAlign="center"
        {...boxProps}
      >
        <Text typoStyle="md" textAlign="left" mb="sm">
          <CustomCounterList
            p={0}
            gridGap={4}
            gridTemplateColumns={{ sm: '1fr 1fr 1fr' }}
          >
            {steps.map(step => {
              return (
                <CustomCounterLi key={step._key}>
                  <H3 typoStyle="md" mb="xs">
                    {step.title}
                  </H3>
                  <Text typoStyle="xs" fontWeight="normal">
                    <BlockContent blocks={step.description} />
                  </Text>
                </CustomCounterLi>
              );
            })}
          </CustomCounterList>
        </Text>
        <Link route="howItWorksPage" passHref>
          <Anchor>
            <FormattedMessage
              id="seeHowItWorks"
              defaultMessage="See how it works"
            />
          </Anchor>
        </Link>
      </Box>
      {demonstrationVideo && (
        <DemonstrationVideo video={demonstrationVideo} showPlayButton={false} />
      )}
    </Container>
  );
}
